

















































































































































































































import Vue from "vue";
import { defineComponent, Ref, ref, watch } from "@vue/composition-api";
import { useErlaubnisinhaber } from "@/lib/vimbApi/useErlaubnisinhaber";

import { Routes } from "@/router/types";
import router from "@/router";
import { Erlaubnisinhaber } from "@/types/vimbApi/erlaubnisinhaber/erlaubnisinhaber";
import { TaetigkeitType } from "@/types/vimbApi/erlaubnisinhaber/taetigkeit";
import { LocationType } from "@/types/vimbApi/erlaubnisinhaber/location";
import {
  Geschaeftsform,
  geschaeftsformenConfig
} from "@/types/vimbApi/erlaubnisinhaber/geschaeftsform";

import ErlaubnisinhaberTabs from "@/components/settings/erlaubnisinhaber/Tabs.vue";
import ErlaubnisinhaberBase from "@/components/settings/erlaubnisinhaber/Base.vue";
import ErlaubnisinhaberAnschrift from "@/components/settings/erlaubnisinhaber/Anschrift.vue";
import ErlaubnisinhaberTaetigkeiten from "@/components/settings/erlaubnisinhaber/Taetigkeiten.vue";
import ErlaubnisinhaberHandelsgesellschaften from "@/components/settings/erlaubnisinhaber/Handelsgesellschaften.vue";
import ErlaubnisinhaberVertreter from "@/components/settings/erlaubnisinhaber/Vertreter.vue";
import ErlaubnisinhaberAnbieterliste from "@/components/settings/erlaubnisinhaber/Anbieterliste.vue";
import ErlaubnisinhaberErlaubnisumfang from "@/components/settings/erlaubnisinhaber/Erlaubnisumfang.vue";

const fixRoute = (
  { value: loading }: Ref<boolean>,
  { value }: Ref<Erlaubnisinhaber[]>,
  { id: currentId, create }: { create?: boolean; id?: number } = {}
) => {
  if (loading || !value || create) return;
  if (value.map(({ id }) => id).includes(currentId)) return;
  router.push({
    name: Routes.ERLAUBNISINHABER,
    params: { id: String(value[0]?.id || "new") }
  });
};

export default defineComponent({
  components: {
    ErlaubnisinhaberTabs,
    ErlaubnisinhaberBase,
    ErlaubnisinhaberAnschrift,
    ErlaubnisinhaberTaetigkeiten,
    ErlaubnisinhaberHandelsgesellschaften,
    ErlaubnisinhaberVertreter,
    ErlaubnisinhaberAnbieterliste,
    ErlaubnisinhaberErlaubnisumfang
  },
  props: {
    id: { type: Number, default: undefined },
    create: { type: Boolean, default: false }
  },
  setup(props) {
    const {
      list,
      listLoading,
      load,
      loading,
      createNew,
      save: saveCurrent,
      remove: removeCurrent
    } = useErlaubnisinhaber(true);
    const current = ref<Erlaubnisinhaber>();
    const dirty = ref<boolean>(false);
    const errors = ref();
    const showError = ref<boolean>(false);
    const showResetConfirm = ref<boolean>(false);
    const showDeleteConfirm = ref(false);
    const form = ref();
    const setCurrent = (item: Erlaubnisinhaber) => (current.value = item);
    const scrollToError = () =>
      Vue.nextTick(() => {
        const el = document.querySelector(".error--text:first-of-type");
        if (!el) return;
        el.scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "center"
        });
      });
    const save = async (erlaubnisinhaber: Erlaubnisinhaber) => {
      if (!form.value.validate()) return scrollToError();
      current.value = undefined;
      const response = await saveCurrent(erlaubnisinhaber);
      setCurrent(response.data);
      errors.value = [
        ...(response.error?.error || []),
        ...Object.values(response.error?.errors || {})
          .flat()
          .map(e => (e as string).replace(/^.*=>/, ""))
      ];
      showError.value = !!response.error;
      if (response.error) return false;
      if (erlaubnisinhaber.id) return true;
      router.push({
        name: Routes.ERLAUBNISINHABER,
        params: { id: response.data.id }
      });
      return true;
    };
    const remove = async (erlaubnisinhaber: Erlaubnisinhaber) => {
      showDeleteConfirm.value = false;
      await removeCurrent(erlaubnisinhaber);
    };
    const loadCurrent = async () => {
      setCurrent(props.id ? await load(props.id) : await createNew());
      form.value.resetValidation();
    };
    const requestResetConfirm = () => {
      showResetConfirm.value = true;
    };
    watch(
      [listLoading, list, () => props.create, () => props.id],
      () => fixRoute(listLoading, list, props),
      { immediate: true }
    );
    watch(
      () => props.id,
      () => {
        if (dirty.value) requestResetConfirm();
        else loadCurrent();
      },
      { immediate: true }
    );
    watch(current, (value, old) => (dirty.value = !!old && value === old), {
      deep: true
    });
    const cancelTabChange = async () => {
      await router.push({
        name: Routes.ERLAUBNISINHABER,
        params: { id: current?.value?.id?.toString() || "new" }
      });
      dirty.value = true;
      showResetConfirm.value = false;
    };
    return {
      list,
      loading,
      current,
      LocationType,
      save,
      errors,
      showError,
      form,
      remove,
      TaetigkeitType,
      dirty,
      showDeleteConfirm,
      showResetConfirm,
      cancelTabChange,
      forceTabChange: () => {
        dirty.value = false;
        showResetConfirm.value = false;
        loadCurrent();
      },
      saveTabChange: async () => {
        if (!current.value) return;
        showResetConfirm.value = false;
        if (await save(current.value)) loadCurrent();
        else cancelTabChange();
      },
      geschaeftsformenConfig: (geschaeftsform = Geschaeftsform.UNKNOWN) =>
        geschaeftsformenConfig[geschaeftsform]
    };
  }
});
